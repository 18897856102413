import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import addCommasToNumber from "utils/misc/addCommasToNumber";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  likeText: {
    ...gStyles.fontSemiBold,
  },
  textContainer: {
    ...gStyles.avalonRegular,
    opacity: 1,
    transition: "opacity 0.3s",
    textAlign: "center",
    width: "100%",
    display: "block",
    marginTop: "0.2rem",
    fontSize: "0.8rem",
  },
  textContainerSaving: {
    opacity: 0,
  },
};

const RatingCount = (props) => {
  const { saving, ratingCount } = props;
  const { styles } = useStyles(baseStyles, props);

  return (
    <span
      className={css(
        styles.textContainer,
        saving && styles.textContainerSaving
      )}
    >
      {addCommasToNumber(ratingCount)}
    </span>
  );
};

RatingCount.propTypes = {
  saving: PropTypes.bool,
  ratingCount: PropTypes.number,
};

RatingCount.defaultProps = {
  saving: false,
  ratingCount: 0,
};

export default memo(RatingCount);
