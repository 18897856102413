import { useMemo, useRef } from "react";

import {
  selectEntity,
  selectEntityFailed,
  selectEntityLoading,
} from "selectors/entity";
import isEqual from "utils/isEqual";

import useReduxState from "hooks/useReduxState";

export default function useEntity(type, id) {
  const entity = useReduxState(
    (state) => (id ? selectEntity(state, id, type) : undefined),
    [id, type]
  );
  const loading = useReduxState(
    (state) => (id ? selectEntityLoading(state, id, type) : undefined),
    [id, type]
  );
  const failed = useReduxState(
    (state) => (id ? selectEntityFailed(state, id, type) : undefined),
    [id, type]
  );

  return useMemo(
    () => ({
      entity,
      loading,
      failed,
    }),
    [entity, loading, failed]
  );
}

export function useSetSubEntity(entity, subType, passedSubRoute = null) {
  const subRoute = useMemo(() => {
    if (!passedSubRoute) {
      return [subType];
    }

    return typeof passedSubRoute === "string"
      ? [passedSubRoute]
      : passedSubRoute;
  }, [passedSubRoute, subType]);

  const needsSubEntity =
    entity &&
    entity.getIn(subRoute) &&
    (typeof entity.getIn(subRoute) === "number" ||
      typeof entity.getIn(subRoute) === "string");

  const { entity: subEntity } = useEntity(
    subType,
    needsSubEntity ? entity.getIn(subRoute) : null
  );

  return useMemo(() => {
    if (needsSubEntity) {
      return entity.setIn(subRoute, subEntity);
    }

    return entity;
  }, [needsSubEntity, subRoute, entity, subEntity]);
}

export function useEntityWithSubEntity(
  entityType,
  entityId,
  subType,
  subRoute
) {
  const { entity } = useEntity(entityType, entityId);

  return useSetSubEntity(entity, subType, subRoute);
}

export function useEntities(type, ids) {
  const entities = useReduxState(
    (state) => {
      if (ids && (ids.length > 0 || ids.size > 0)) {
        return ids
          .filter((id) => !!id)
          .map((id) => ({
            entity: selectEntity(state, id, type),
            loading: selectEntityLoading(state, id, type),
            failed: selectEntityFailed(state, id, type),
          }));
      }

      return ids;
    },
    [ids, type]
  );

  // the section below makes sure the useMemo entities update, without causing a reupdate everytime the new entities are mapped
  const lastEntities = useRef(entities);

  if (
    (!lastEntities.current && !!entities) ||
    (lastEntities.current && !isEqual(lastEntities.current, entities))
  ) {
    lastEntities.current = entities;
  }

  const finalEntities = lastEntities.current;

  return useMemo(
    () => finalEntities,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [finalEntities]
  );
}
