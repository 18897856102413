import { Map } from "immutable";

import getEpisodeUrl from "./getEpisodeUrl";
import getPodcastUrl from "./getPodcastUrl";

import { getBaseUrl } from "constants/base";

const getEntityUrlFunc = {
  episode: getEpisodeUrl,
  podcast: getPodcastUrl,
};

export default (review, relative = false, options = {}) => {
  if (!review) {
    return "";
  }

  const getUrl = getEntityUrlFunc[review.get("entity_type")];

  const reviewHasEntity =
    review &&
    review.has("entity") &&
    Map.isMap(review.get("entity")) &&
    (review.get("entity_type") === "episode"
      ? Map.isMap(review.getIn(["entity", "podcast"]))
      : true);

  const relativePath = `${
    getUrl
      ? getUrl(reviewHasEntity ? review.get("entity") : options.reviewedEntity)
      : ""
  }/reviews/${review.get("id")}`;

  return relative ? relativePath : `${getBaseUrl()}${relativePath}`;
};
