import { css } from "aphrodite";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import { memo } from "react";

import addCommasToNumber from "utils/misc/addCommasToNumber";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  likeText: {
    ...gStyles.fontSemiBold,
  },
  textContainer: {
    opacity: 1,
    transition: "opacity 0.3s",
    fontSize: "0.8rem",
    textAlign: "center",
    display: "block",
  },
  textContainerSaving: {
    opacity: 0,
  },
  likedText: {},
};

const RatingText = (props) => {
  const {
    entityName,
    saving,
    ratedByUser,
    ratingCount,
    friendRatingCount,
    noRatingMessage,
    ratedText,
    userRating,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderLikeCount = (count, showAndCondition, creatorType = "other") => (
    <span>
      &nbsp;
      {showAndCondition && <span>and&nbsp;</span>}
      <span className={css(styles.likeText)}>
        {addCommasToNumber(count)} {pluralize(creatorType, count)}
      </span>
    </span>
  );

  const like_count = ratedByUser ? ratingCount - 1 : ratingCount;
  const friend_like_count = friendRatingCount; // TODO hook up
  const hasFriendLike = friend_like_count > 0;
  const hasOtherLike = like_count > 0;
  const hasLike = ratedByUser || hasFriendLike || hasOtherLike;
  let finalRatedText = "rated";

  if (ratedText) {
    finalRatedText = ratedText;
  } else if (userRating > 0 && !hasFriendLike && !hasOtherLike) {
    if (userRating > 3) {
      finalRatedText = "loved";
    } else if (userRating < 3) {
      finalRatedText = "disliked";
    } else {
      finalRatedText = "liked";
    }
  }

  return (
    <div
      className={css(
        styles.textContainer,
        saving && styles.textContainerSaving
      )}
    >
      {ratedByUser && <span className={css(styles.likeText)}>You</span>}
      {!hasLike && (
        <span>
          {noRatingMessage && `Good ${entityName}? Give it some love!`}
        </span>
      )}
      {hasFriendLike &&
        renderLikeCount(friend_like_count, ratedByUser, "friend")}
      {hasOtherLike &&
        renderLikeCount(
          like_count,
          ratedByUser || hasFriendLike,
          ratedByUser || hasFriendLike ? "other" : "person"
        )}
      {hasLike && (
        <span
          className={css(styles.likedText)}
        >{` ${finalRatedText} this ${entityName}`}</span>
      )}
    </div>
  );
};

RatingText.propTypes = {
  entityName: PropTypes.node,
  saving: PropTypes.bool,
  ratedByUser: PropTypes.bool,
  ratingCount: PropTypes.number,
  friendRatingCount: PropTypes.number,
  noRatingMessage: PropTypes.string,
  ratedText: PropTypes.string,
  userRating: PropTypes.number,
};

RatingText.defaultProps = {
  entityName: null,
  saving: false,
  ratedByUser: false,
  ratingCount: 0,
  friendRatingCount: 0,
  noRatingMessage: null,
  ratedText: null,
  userRating: 0,
};

export default memo(RatingText);
