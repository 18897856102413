import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { selectAuthLoggedIn, selectAuthUser } from "selectors/auth";
import { selectSpecificUser } from "selectors/user";
import getReviewUrl from "utils/entity/getReviewUrl";
import getUserDisplayName from "utils/entity/getUserDisplayName";

import useReduxState from "hooks/useReduxState";
import useReviewWithEntity from "hooks/useReviewWithEntity";

const ReviewSharingLinks = (props) => {
  const { review: passedReview, eventProps } = props;

  const pulledReview = useReviewWithEntity(
    passedReview && passedReview.get("id")
  );
  const review = pulledReview || Map();
  const user = useReduxState(
    (state) => selectSpecificUser(state, review.get("user")),
    [review]
  );

  const reviewedByLoggedInUser = useReduxState(
    (state) =>
      selectAuthLoggedIn(state) &&
      selectAuthUser(state).get("id") === review.get("user_id"),
    [review]
  );

  const username = getUserDisplayName(user);
  const title = review.getIn(["entity", "title"]);
  const reviewCreatorText = reviewedByLoggedInUser ? "my" : `${username}'s`;

  const starsText = review.has("rating")
    ? `${"⭐️".repeat(review.get("rating"))} ` // Don't remove this space after the stars
    : "";

  const info = useMemo(
    () => ({
      url: getReviewUrl(review, false),
      title: `Check out ${reviewCreatorText} ${starsText}review of ${title} on Podchaser`,
      twitterTitle: `Check out ${reviewCreatorText} ${starsText}review of ${title} on @Podchaser`,
      image_url: `${review.getIn(["entity", "image_url"])}`,
    }),
    [review, reviewCreatorText, starsText, title]
  );

  const finalEventProps = useMemo(
    () => ({
      entity_id: review.get("id"),
      entity_type: "review",
      ...eventProps,
    }),
    [review, eventProps]
  );

  return (
    <EntitySharingLinks
      {...props}
      entity={review}
      entity_type="review"
      info={info}
      eventProps={finalEventProps}
    />
  );
};

ReviewSharingLinks.propTypes = {
  review: PropTypes.object.isRequired,
  eventProps: PropTypes.object,
};
ReviewSharingLinks.defaultProps = {
  eventProps: {},
};

export default memo(ReviewSharingLinks);
