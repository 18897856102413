import { useMemo } from "react";

import useEntity from "hooks/useEntity";
import useLoggedInUser from "hooks/useLoggedInUser";

export const useIsPodcastOwner = (podcast, podcastEntity = null) => {
  const loggedInUser = useLoggedInUser();

  if (loggedInUser) {
    const combinedPodcast = podcast || podcastEntity;
    if (combinedPodcast && combinedPodcast.hasIn(["settings", "owners"])) {
      const ownerIds = combinedPodcast.getIn(["settings", "owners"]);

      return (
        ownerIds &&
        ownerIds.find((owner) => owner.get("id") === loggedInUser.get("id"))
      );
    }

    const ownerIds = combinedPodcast && combinedPodcast.get("owner_ids");

    return (
      ownerIds && ownerIds.find((ownerId) => ownerId === loggedInUser.get("id"))
    );
  }

  return false;
};

export default (review) => {
  const user = useLoggedInUser();

  const entityType = useMemo(
    () => review && review.get("entity_type"),
    [review]
  );

  const { entity } = useEntity(entityType, review && review.get("entity_id"), [
    review,
  ]);

  const entityPodcastId = useMemo(
    () => entity && entityType === "episode" && entity.get("podcast_id"),
    [entity, entityType]
  );

  const podcast_id = useMemo(() => {
    if (entityPodcastId) {
      return entityPodcastId;
    }
    if (entityType === "podcast") {
      return review.get("entity_id");
    }
    const podcastIdFromEpisode =
      review &&
      review.getIn(["entity", "podcast"]) &&
      (typeof review.getIn(["entity", "podcast"]) === "number" ||
        typeof review.getIn(["entity", "podcast"]) === "string")
        ? review.getIn(["entity", "podcast"])
        : null;

    return podcastIdFromEpisode;
  }, [review, entityType, entityPodcastId]);

  const { entity: podcast } = useEntity("podcast", podcast_id);
  const isPodcastOwner = useIsPodcastOwner(podcast);
  // const { entity: creator } = useEntity('creator', user && user.get('creator'));

  const possibleProfiles = useMemo(() => {
    const profiles = [];

    if (!user) {
      return profiles;
    }

    /*
    if (creator) { // DISABLED TEMPORARILY
      profiles.push({
        entity_type: 'creator',
        entity_id: creator.get('id'),
        entity: creator,
      });
    }
    */
    if (isPodcastOwner) {
      profiles.push({
        entity_type: "podcast",
        entity_id: podcast.get("id"),
        entity: podcast,
      });
    }

    return profiles;
  }, [podcast, user, isPodcastOwner]);

  return {
    canReply: possibleProfiles.length > 0,
    possibleProfiles,
    podcast,
    user,
  };
};
